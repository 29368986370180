/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * immerse.education (monthly pageviews: <50K)
 *
 */

/* complete */

@font-face {
  font-family: 'Circular';
  font-weight: 100;
  src: url('CircularXXWeb-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 100;
  src: url('CircularXXWeb-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 500;
  src: url('CircularXXWeb-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 500;
  src: url('CircularXXWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 500;
  src: url('CircularXXWeb-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 450;
  src: url('CircularXXWeb-BookItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 450;
  src: url('CircularXXWeb-Book.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 450;
  src: url('CircularXXWeb-Book.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 450;
  src: url('CircularXXWeb-BookItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 500;
  src: url('CircularXXWeb-BookItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 100;
  src: url('CircularXXWeb-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 100;
  src: url('CircularXXWeb-ThinItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 600;
  src: url('CircularXXWeb-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'CircularXX';
  font-style: italic;
  font-weight: 600;
  src: url('CircularXXWeb-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 700;
  src: url('CircularXXWeb-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 700;
  src: url('CircularXXWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 300;
  src: url('CircularXXWeb-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 300;
  src: url('CircularXXWeb-LightItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 400;
  src: url('CircularXXWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 400;
  src: url('CircularXXWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 800;
  src: url('CircularXXWeb-BlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 800;
  src: url('CircularXXWeb-BlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 700;
  src: url('CircularXXWeb-Black.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 700;
  src: url('CircularXXWeb-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 900;
  src: url('CircularXXWeb-ExtraBlackItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 900;
  src: url('CircularXXWeb-ExtraBlackItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Light';
  font-weight: 300;
  src: url('CircularXXWeb-Light.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 300;
  src: url('CircularXXWeb-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 900;
  src: url('CircularXXWeb-ExtraBlack.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 900;
  src: url('CircularXXWeb-ExtraBlack.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-weight: 600;
  src: url('CircularXXWeb-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-weight: 600;
  src: url('CircularXXWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 400;
  src: url('CircularXXWeb-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 400;
  src: url('CircularXXWeb-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 700;
  src: url('CircularXXWeb-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Circular';
  font-style: italic;
  font-weight: 700;
  src: url('CircularXXWeb-BoldItalic.woff2') format('woff2');
}
